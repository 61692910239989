import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_component_el_button, {
    type: _ctx.btnType,
    size: _ctx.btnSize,
    disabled: _ctx.disabled,
    style: _ctx.styles,
    icon: _ctx.btnIcon,
    onClick: _ctx.handleExportBefore
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.btnText), 1)
    ]),
    _: 1
  }, 8, ["type", "size", "disabled", "style", "icon", "onClick"]))
}