/*
 * @Description: 导出文件并下载
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2021-04-15 11:10:33
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-04-15 11:12:21
 */
import CExportFile from './index.vue'
export default CExportFile
