
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import CExportFile from '@/components/c-export-file'
import { getIndexConfig, getTableConfig } from './config'
import { getPageOfEnterprise, sumApi, paymentDelAPI } from './api'
import CButtonGroup from '@/components/c-button-group'
import detail from './detail.vue'
import { btnName } from '@/utils/index'
import dayjs from 'dayjs'
import { defineComponent, toRefs, reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { toResult } from '@/utils/toResult'
import paymentEidt from './eidt.vue'

export default defineComponent({
  components: {
    CSearchList,
    CTable,
    detail,
    CExportFile,
    CButtonGroup,
    paymentEidt
  },
  setup() {
    const data = reactive({
      ...getIndexConfig(),
      fileTitle: '缴费记录' + dayjs().format('YYYY-MM-DD'),
      sumDate: ref<any>({}),
      isPaymentOperation: false
    })
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })

    /**
     * 查询
     */
    const getListFn = () => {
      const obj = Object.assign(
        {},
        { ...data.queryForm },
        {
          currPage: tableConfig.pagination.currentPage,
          pageSize: tableConfig.pagination.pageSize,
          createTimeStart:
            (data.queryForm as any).dateTimeArea instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea[0]).format(
                'YYYY-MM-DD 00:00:00'
              )
              : '',
          createTimeEnd:
            (data.queryForm as any).dateTimeArea instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea[1]).format(
                'YYYY-MM-DD 23:59:59'
              )
              : ''
        }
      )
      getPageOfEnterprise(obj).then(res => {
        tableConfig.data = res.data.list
        tableConfig.pagination.total = res.data.total
      })

      sumApi(obj).then(res => {
        data.sumDate = res.data
      })
    }
    /**
     * 重置
     */
    const handleReset = val => {
      data.queryForm = val
      getListFn()
    }
    const handleSearch = (params: object) => {
      data.queryForm = Object.assign({}, params)
      getListFn()
    }

    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListFn()
    }
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getListFn()
    }

    // 查看详情
    const look = (params?: any) => {
      data.visible = true
      data.rowData = params
      console.log(params)
    }

    const requsetDel = async (row) => {
      const [err, res] = await toResult(paymentDelAPI({ id: row.id }))
      if (err) return false
      console.log(res)
      ElMessage.success('删除成功')
      getListFn()
    }

    // 删除
    const del = row => {
      ElMessageBox.confirm('确认删除？', '提示').then(() => {
        requsetDel(row)
      }).catch(() => {})
    }

    const onToggle = (attr, row?) => {
      data[attr] = !data[attr]
      row && (data.rowData = row)
    }

    onMounted(() => {
      handleSearch({
        dateTimeArea: [(new Date() as any) - 3600 * 1000 * 24 * 30, new Date()]
      })
    })
    return {
      handleReset,
      handleSearch,
      pageCurrentChange,
      pageSizeChange,
      tableConfig,
      btnName,
      look,
      del,
      getListFn,
      onToggle,
      ...toRefs(data)
    }
  }
})
