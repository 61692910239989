
import fileDownload from 'js-file-download'
import axios, { AxiosRequestConfig, Method } from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ElMessageBoxComponent } from 'element-plus/lib/el-message-box/src/message-box.type'
import { defineComponent, PropType } from 'vue'
import { getToken } from '@/utils/auth'
import { setBaseUrl } from '@/config/url'

export default defineComponent({
  name: 'CExportFile',
  props: {
    // 导出文件请求入参
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 导出文件请求URl
    url: {
      type: String,
      required: true
    },
    // 请求方式
    method: {
      type: String as PropType<Method>,
      default: 'post'
    },
    // 导出文件名称
    fileTitle: {
      type: String,
      default: '文件名称'
    },
    // 导出文件类型
    fileType: {
      type: String,
      default: 'xlsx'
    },
    // 是否禁用导出按钮
    disabled: {
      type: Boolean,
      default: false
    },
    // 点击导出按钮是否弹出确认框 默认弹出
    isConfirm: {
      type: Boolean,
      default: true
    },
    // 按钮主题 primary / success / warning / danger / info / text
    btnType: {
      type: String,
      default: 'primary'
    },
    // 导出按钮文字描述
    btnText: {
      type: String,
      default: '导出'
    },
    // 导出按钮大小 medium / small / mini
    btnSize: {
      type: String,
      default: 'default'
    },
    // 导出按钮 图标
    btnIcon: {
      type: String,
      default: 'el-icon-download'
    },
    // 自定义按钮样式
    styles: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    // 导出下载的文件名称及格式
    const fileName = `${props.fileTitle}.${props.fileType}`

    /**
     * @description: 执行下载到本地
     * @param {*} res 接口返回的数据
     * @return {*}
     */
    const handleDownload = (res: any): void => {
      const temp = res.headers['content-disposition'].split(';')[1].split('filename=')[1]
      const _fileName = fileName || decodeURIComponent(temp)
      if (res.data instanceof Blob && res.data.type) {
        fileDownload(res.data, _fileName)
        const msg = res.data.message || '导出成功'
        ElMessage.success(msg)
        emit('onSuccess')
      } else {
        const msg = res.data.message || '服务器错误!'
        ElMessage.error(msg)
      }
    }

    /**
     * @description: 发送请求
     * @param {ElMessageBoxComponent} instance ElMessageBox.confim 的实例
     * @param {Function} done ElMessageBox.confirm 关闭函数
     * @return {void}
     */
    const handleRequest = (
      instance?: ElMessageBoxComponent,
      done?: () => void
    ): void => {
      instance && (instance.confirmButtonLoading = true)
      instance && (instance.confirmButtonText = '导出中...')
      const _params = props.method === 'get' ? 'params' : 'data'
      // 请求选项
      const options: AxiosRequestConfig = {
        method: props.method,
        headers: {
          token: getToken()
        },
        baseURL: setBaseUrl().baseUrl + '/ccclubs-admin',
        url: props.url,
        responseType: 'blob'
      }
      options[_params] = props.params
      // 请求发送
      axios(options)
        .then(res => {
          console.log(res)

          if (res.data.type === 'application/json') {
            const reader = new FileReader()
            reader.readAsText(res.data, 'utf-8')
            reader.onload = e => {
              console.log(e)
              const readerres: any = reader.result
              let parseObj: any = {}
              parseObj = JSON.parse(readerres)
              console.log(parseObj.msg)
              ElMessage.error(JSON.stringify(parseObj.msg))
            }
            done && done()
            return false
          }
          instance && (instance.confirmButtonLoading = false)
          done && done()
          handleDownload(res)
        })
        .catch(err => {
          console.log(err)
          instance && (instance.confirmButtonLoading = false)
          ElMessage.error(JSON.stringify(err.message))
          done && done()
        })
    }

    /**
     * @description: 弹出确认弹窗
     * @param {*}
     * @return {void}
     */
    const handleConfirm = (): void => {
      const title = '导出'
      const message = `确定导出 <strong>${fileName}</strong> `
      ElMessageBox.confirm(message, title, {
        dangerouslyUseHTMLString: true,
        closeOnClickModal: false,
        showClose: false,
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            handleRequest(instance, done)
          } else {
            done()
          }
        }
      })
        .then(() => { })
        .catch(() => { })
    }

    /**
     * @description: 导出之前
     * @param {*}
     * @return {void}
     */
    const handleExportBefore = (): void => {
      if (props.isConfirm) {
        handleConfirm()
      } else {
        handleRequest()
      }
    }

    return { handleExportBefore }
  }
})
